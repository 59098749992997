<div class="dvb-schulung-tarife-leistungsrechnung">
    <h2 translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.TITLE"></h2>
    <h3 translate="SCHULUNG.ABLAUF" class="normal-abstand-top"></h3>

    <ul class="floating-text no-padding normal-abstand-top">
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.TARIF_ERSTELLEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.TARIF_BEARBEITEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.TARIF_KITA_ZUWEISEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.TARIF_FIRMA_ZUWEISEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.KIND_PARAMETER_SETZEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.KIND_BETREUUNGS_GUTSCHEIN_SETZEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.FAVORIT_ERSTELLEN"></li>
        <li translate="SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.STEPS.AUFGABEN_ERLEDIGEN"></li>
    </ul>
</div>
