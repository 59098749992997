<div class="dvb-schulung-faktura">
    <h2 translate="SCHULUNG.FAKTURA.TITLE"></h2>

    <h3 translate="SCHULUNG.ABLAUF" class="normal-abstand-top"></h3>

    <ul class="floating-text no-padding normal-abstand-top">
        <li translate="SCHULUNG.FAKTURA.STEPS.EINRICHTUNG_DVB_FAKTURA"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.RECHNUNGSKONFIGURATION_EINER_KITA_ZUWEISEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.RECHNUNGSLAUF_ERSTELLEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.RECHNUNGSKORREKTUR_ERSTELLEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.RECHNUNG_STORNIEREN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.RECHNUNG_BEZAHLEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.RECHNUNG_MAHNEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.OFFENE_POSTEN_VORAUSZAHLUNGEN_UND_RUECKERSTATTUNGEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.UEBERSCHUESSIG_BEZAHLTE_RECHNUNGEN"></li>
        <li translate="SCHULUNG.FAKTURA.STEPS.DER_KONTENABGLEICH"></li>
    </ul>
</div>
