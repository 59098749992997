<div class="dvb-schulung-main">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <h1 [tooltip]="'SCHULUNG.SCHULUNG' | translate">{{ 'SCHULUNG.SCHULUNG' | translate }}</h1>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <h2 translate="SCHULUNG.SCHULUNG_AUSWAEHLEN"></h2>

            @for (schulung of schulungen; track schulung.state) {
                <dv-accordion [expanded]="currentStateName() === schulung.state"
                              [index]="$index"
                              (toggle)="goTo(schulung)">
                    <div accordionHeader class="header">
                        <span translate="{{schulung.titleKey}}"></span>
                    </div>
                    <div accordionBody>
                        <h3 translate="SCHULUNG.INHALT"></h3>
                        <p translate="{{schulung.subjectKey}}"></p>
                        <h3 translate="SCHULUNG.ZIEL" class="small-abstand-top"></h3>
                        <p translate="{{schulung.goalKey}}"></p>
                    </div>
                </dv-accordion>
            }
        </div>

        <div class="col-md-4 col-md-offset-1">
            <!-- CONTENT from sub-states -->
            <ui-view></ui-view>

            @if (currentStateName() === 'base.schulung') {
                <div>
                    <h2 translate="SCHULUNG.WIE_FUNKTIONIERTS"></h2>
                    <ul>
                        <li translate="SCHULUNG.SCHULUNG_AUSWAEHLEN"></li>
                        <li translate="SCHULUNG.SCHULUNG_ERSTELLEN"></li>
                        <li translate="SCHULUNG.SCHULUNG_DURCHFUEHREN"></li>
                    </ul>
                    <p translate="SCHULUNG.BESCHREIBUNG"></p>
                </div>
            } @else {
                <div class="big-abstand-top">
                    <dvlib-button-list>
                        <dvlib-loading-button (clickEvent)="createSchulungsMandant()"
                                              [isLoading]="isLoading()">
                            <span translate="SCHULUNG.SCHULUNG_ERSTELLEN"></span>
                        </dvlib-loading-button>
                        @if (assets(); as assets) {
                            <a [attr.href]="assets" target="_blank" translate="SCHULUNG.UNTERLAGEN_ANSEHEN"></a>
                        }
                    </dvlib-button-list>

                    @if (currentSchulungsMandant(); as mandant) {
                        <div class="created-mandant-info">
                            <p translate="SCHULUNG.SCHULUNGSBENUTZER_CREATED_AND_DATA_INITIALIZED"></p>
                            <p [innerHTML]="'SCHULUNG.BENUTZERNAME_VALUE' | translate: {value: mandant?.name}"></p>
                            <p [innerHTML]="'SCHULUNG.PASSWORT_VALUE' | translate: {value: mandant?.password}"></p>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
