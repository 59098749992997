/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import {UserRole} from '@dv/shared/roles';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import {
    SchulungEintrittBisAustrittComponent,
} from './component/schulung-eintritt-bis-austritt/schulung-eintritt-bis-austritt.component';
import {SchulungFakturaComponent} from './component/schulung-faktura/schulung-faktura.component';
import {SchulungMainComponent} from './component/schulung-main/schulung-main.component';
import {
    SchulungModellierungKitaComponent,
} from './component/schulung-modellierung-kita/schulung-modellierung-kita.component';
import {SchulungPersonalComponent} from './component/schulung-personal/schulung-personal.component';
import {SchulungSupportComponent} from './component/schulung-support/schulung-support.component';
import {
    SchulungTarifeLeistungsrechnungComponent,
} from './component/schulung-tarife-leistungsrechnung/schulung-tarife-leistungsrechnung.component';

export const BASE_STATE_NAME = 'base.schulung';

export const BASE_STATE = {
    name: BASE_STATE_NAME,
    url: '/schulung',
    component: SchulungMainComponent,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'SCHULUNG.SCHULUNG'),
        ],
    },
} satisfies Ng2StateDeclaration;

export const EINTRITT_AUSTRITT = {
    name: `${BASE_STATE_NAME}.eintrittbisaustritt`,
    url: '/eintrittbisaustritt',
    component: SchulungEintrittBisAustrittComponent,
} satisfies Ng2StateDeclaration;

export const KITA_MODELLIERUNG = {
    name: `${BASE_STATE_NAME}.modellierungkita`,
    url: '/modellierungkita',
    component: SchulungModellierungKitaComponent,
} satisfies Ng2StateDeclaration;

export const TARIF_LEISTUNGS_RECHNUNG = {
    name: `${BASE_STATE_NAME}.tarifeleistungsrechnung`,
    url: '/tarifeleistungsrechnung',
    component: SchulungTarifeLeistungsrechnungComponent,
} satisfies Ng2StateDeclaration;

export const FAKTURA = {
    name: `${BASE_STATE_NAME}.faktura`,
    url: '/faktura',
    component: SchulungFakturaComponent,
} satisfies Ng2StateDeclaration;

export const PERSONAL = {
    name: `${BASE_STATE_NAME}.personal`,
    url: '/personal',
    component: SchulungPersonalComponent,
    data: {
        permissions: PERMISSION.MODULE.PERSONALPLANUNG,
    },
} satisfies Ng2StateDeclaration;

export const SUPPORT = {
    name: `${BASE_STATE_NAME}.support`,
    url: '/support',
    component: SchulungSupportComponent,
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },
} satisfies Ng2StateDeclaration;
