/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnInit, WritableSignal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, signal} from '@angular/core';
import {SchulungsMandant} from '@dv/kitadmin/models';
import {AccordionComponent} from '@dv/kitadmin/ui';
import {AuthStore, ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {SchulungService} from '@dv/shared/backend/api/schulung.service';
import {JaxSchulungsMandantInformation} from '@dv/shared/backend/model/jax-schulungs-mandant-information';
import {UserRole} from '@dv/shared/roles';
import {TranslateModule} from '@ngx-translate/core';
import {StateService, UIRouterGlobals, UIRouterModule} from '@uirouter/angular';
import type {TransitionPromise} from '@uirouter/core/lib/state/interface';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {Observable} from 'rxjs';
import {catchError, EMPTY, tap, throwError} from 'rxjs';
import {
    EINTRITT_AUSTRITT,
    FAKTURA,
    KITA_MODELLIERUNG,
    PERSONAL,
    SUPPORT,
    TARIF_LEISTUNGS_RECHNUNG,
} from '../../schulung-states';

interface Schulung {
    titleKey: string;
    subjectKey: string;
    goalKey: string;
    state: string;
}

@Component({
    selector: 'dv-schulung-main',
    standalone: true,
    imports: [
        CommonModule,
        TooltipModule,
        TranslateModule,
        UIRouterModule,
        LoadingButtonComponent,
        ButtonListComponent,
        AccordionComponent,
    ],
    templateUrl: './schulung-main.component.html',
    styleUrls: ['./schulung-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchulungMainComponent implements OnInit {

    public schulungen: Schulung[] = [
        {
            titleKey: 'SCHULUNG.EINTRITT_BIS_AUSTRITT.TITLE',
            subjectKey: 'SCHULUNG.EINTRITT_BIS_AUSTRITT.SUBJECT',
            goalKey: 'SCHULUNG.EINTRITT_BIS_AUSTRITT.GOAL',
            state: EINTRITT_AUSTRITT.name,
        },
        {
            titleKey: 'SCHULUNG.MODELLIERUNG_KITA.TITLE',
            subjectKey: 'SCHULUNG.MODELLIERUNG_KITA.SUBJECT',
            goalKey: 'SCHULUNG.MODELLIERUNG_KITA.GOAL',
            state: KITA_MODELLIERUNG.name,
        }, {
            titleKey: 'SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.TITLE',
            subjectKey: 'SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.SUBJECT',
            goalKey: 'SCHULUNG.TARIFE_LEISTUNGSRECHNUNG.GOAL',
            state: TARIF_LEISTUNGS_RECHNUNG.name,
        },
        {
            titleKey: 'SCHULUNG.FAKTURA.TITLE',
            subjectKey: 'SCHULUNG.FAKTURA.SUBJECT',
            goalKey: 'SCHULUNG.FAKTURA.GOAL',
            state: FAKTURA.name,
        },
    ];

    public isLoading = signal(false);
    public currentStateName = signal('');
    public schulungsMandant: WritableSignal<{ [key: string]: SchulungsMandant }> = signal({});

    public currentSchulungsMandant = computed(() => {
        const schulungsMandant = this.schulungsMandant();
        const currentStateName = this.currentStateName();

        return schulungsMandant[currentStateName];
    });

    public assets = computed(() => {
        const stateName = this.currentStateName();
        switch (stateName) {
            case EINTRITT_AUSTRITT.name:
                return 'https://blog.kitadmin.ch/schulung-1-von-der-anmeldung-bis-zum-austritt-default';
            case KITA_MODELLIERUNG.name:
                return 'https://blog.kitadmin.ch/schulung-2-kinderort-modellieren-default';
            case TARIF_LEISTUNGS_RECHNUNG.name:
                return 'https://blog.kitadmin.ch/schulung-3-tarife-und-leistungsrechnung-default';
            case FAKTURA.name:
                return 'https://blog.kitadmin.ch/schulung-4-faktura-default';
            case PERSONAL.name:
                return 'https://blog.kitadmin.ch/schulung-5-personal-default';
            case SUPPORT.name:
                return null;
            default:
                throw new Error(`No Schulung assigned to state ${JSON.stringify(this.currentStateName)}`);
        }
    });

    public constructor(
        private readonly schulungService: SchulungService,
        private readonly stateService: StateService,
        private readonly uiRouterGlobals: UIRouterGlobals,
        authStore: AuthStore,
    ) {

        if (authStore.hasPermission(PERMISSION.MODULE.PERSONALPLANUNG)) {
            // for now, personal schulung only for mandanten with personal module
            this.schulungen.push({
                titleKey: 'SCHULUNG.PERSONAL.TITLE',
                subjectKey: 'SCHULUNG.PERSONAL.SUBJECT',
                goalKey: 'SCHULUNG.PERSONAL.GOAL',
                state: PERSONAL.name,
            });
        }

        if (authStore.hasRole(UserRole.MANDANT_ADMIN)) {
            this.schulungen.push({
                titleKey: 'SCHULUNG.SUPPORT.TITLE',
                subjectKey: 'SCHULUNG.SUPPORT.SUBJECT',
                goalKey: 'SCHULUNG.SUPPORT.GOAL',
                state: SUPPORT.name,
            });
        }

    }

    public ngOnInit(): void {
        this.currentStateName.set(this.uiRouterGlobals.current.name!);
    }

    public createSchulungsMandant(): void {
        this.isLoading.set(true);
        this.getSchulungsResource().pipe(
            tap(schulungsMandant => {
                const update = {...this.schulungsMandant()};
                update[this.uiRouterGlobals.current.name!] = SchulungsMandant.apiResponseTransformer(schulungsMandant);
                this.schulungsMandant.set(update);
                this.isLoading.set(false);
            }),
            catchError(() => {
                this.isLoading.set(false);

                return EMPTY;
            }))
            .subscribe();
    }

    public goTo(schulung: Schulung): TransitionPromise {
        this.currentStateName.set(schulung.state);

        return this.stateService.go(schulung.state);
    }

    private getSchulungsResource(): Observable<JaxSchulungsMandantInformation> {
        switch (this.currentStateName()) {
            case EINTRITT_AUSTRITT.name:
                return this.schulungService.schulungEintrittAustritt$();
            case KITA_MODELLIERUNG.name:
                return this.schulungService.schulungModellierungKita$();
            case TARIF_LEISTUNGS_RECHNUNG.name:
                return this.schulungService.schulungTarifeLeistungsrechnung$();
            case FAKTURA.name:
                return this.schulungService.schulungFaktura$();
            case PERSONAL.name:
                return this.schulungService.schulungPersonal$();
            case SUPPORT.name:
                return this.schulungService.schulungSupport$();
            default:
                return throwError(() =>
                    new Error(`No Schulung assigned to state ${JSON.stringify(this.currentStateName)}`));
        }
    }
}
